import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { ReferralStateType } from "types/referral";

import { extraReducers } from "./referral.extraReducers";
export const initialState: ReferralStateType = {
  isLoading: false,
  dataVipMember: {
    data: {
      code: "",
      referral: {
        total: 0,
        detail: [
          {
            member_id: "",
            registered_date: "",
            total_comm: "",
          },
        ],
      },
      valid_referral: {
        total: 0,
        detail: [
          {
            member_id: "",
            registered_date: "",
            total_turnover: "",
          },
        ],
      },
      reward: {
        total: "",
        detail: [
          {
            date: "",
            distribution_time: "",
            total_reward: "",
            total_turnover: "",
          },
        ],
      },
    },
  },
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    //
  },
  extraReducers,
});

export const selectDataReferral = (state: ApplicationRootState) =>
  state.referral.dataVipMember.data;
export const selectLoadingReferral = (state: ApplicationRootState) =>
  state.referral.isLoading;

export default referralSlice.reducer;

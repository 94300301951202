import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  ForgotPasswordFormType,
  LoginFormType,
  LoginResponse,
  PayloadCheckOnOff,
  RegisterFormType,
  RequestOTPType,
  VerifyTokenPayload,
} from "./type";

export const AuthApi = {
  login: (typename: string, payload: LoginFormType) =>
    axiosRequest.post<LoginResponse>(
      "/",
      payloadHelper("MemberLogin", typename, payload)
    ),
  register: (typename: string, payload: RegisterFormType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("MemberRegister", typename, {
        ...payload,
        domain:
          window.location.hostname === "localhost"
            ? "agt91.99bb.net"
            : window.location.hostname,
      })
    ),
  forgotPassword: (typename: string, payload: ForgotPasswordFormType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ForgotPassword", typename, payload)
    ),
  requestOTP: (payload: RequestOTPType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("RequestOTP", payload.username, payload)
    ),
  async verifyToken(typename: string, payload: VerifyTokenPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("VerifyToken", typename, payload)
    );
    return data;
  },
  async getCheckPromotionOnOff(payload: PayloadCheckOnOff) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("checkPromotionOnOff", payload.username, payload)
    );
    return data;
  },
};

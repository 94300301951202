import { css } from "@emotion/react";

export const ContainerStyle = {
  self: css`
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 12px;
    padding-left: 12px;
    max-width: 1200px;
  `,
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthApi } from "api/auth";
import { LoginFormType, RegisterFormType } from "api/auth/type";
import { ResponseCode } from "contants/response";
import { getProfile } from "store/user/user.thunk";
import { ApiResponse } from "types/api";

export const signInWithCredentials = createAsyncThunk(
  "auth/signInWithCredentials",
  async (formData: LoginFormType, thunkApi) => {
    try {
      const response = await AuthApi.login(formData.username, formData);
      const responseData = response.data;
      if (responseData.status === ResponseCode.SUCCESS) {
        const { dispatch } = thunkApi;
        dispatch(getProfile(formData.username));
      } else {
        return thunkApi.rejectWithValue(responseData.msg);
      }
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const registerMemberDetail = createAsyncThunk(
  "auth/registerMemberDetail",
  async ({ payload }: { payload: RegisterFormType }, thunkApi) => {
    try {
      const response = await AuthApi.register(payload.username, payload);

      const responseData: ApiResponse = response.data;

      if (responseData.status !== ResponseCode.SUCCESS) return responseData;
      const { dispatch } = thunkApi;

      dispatch(
        signInWithCredentials({
          username: payload.username,
          password: payload.password,
        })
      );

      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

import { Theme } from "@emotion/react";

export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    redBlack: "#B02828",
    redHover: "#8d1b1b",
    lightText: "#83696A",
    darkGreen: "#BD161D",
    gray: {
      0: "#f0f0f0",
      10: "#c8c8ca",
      100: "#AFAFB5",
      150: "#E0E0E0",
      200: "#95959D",
      250: "#b8b8b8",
      300: "#636369",
      350: "#e2e2e2",
      400: "#c3c3c3",
      500: "#F6F2F2",
    },
    gradient: {
      1: "linear-gradient(194deg, #E81825 9.53%, #C01519 90.21%)",
      2: " linear-gradient(180deg, #FAFAFA 0%, #F4E7E8 100%)",
      3: " linear-gradient(180deg, #F31E27 0%, #BD161D 100%)",
      4: "linear-gradient(180deg, #fff, #f0f6ff)",
      5: "rgba(107, 123, 168, 0.25)",
      6: "linear-gradient(180deg, #FAFAFA 0%, #F4E7E8 100%)",
    },
    text: {
      black: "#1C1C1E",
      green: "#10AC02",
      darkGreen: "#83696A",
      white_light: "#EDEDED",
      orange: "#ee6f4f",
      back: "#ecd2d3",
    },
    bank: {
      1: "#044FA2",
      2: "#238952",
      3: "#E1AA31",
      4: "#245D7A",
      5: "#2F2851",
      6: "#6B8DBD",
      7: "#C7B141",
      8: "#24AEB9",
      9: "#2C5781",
      10: "#DF621C",
    },
    brown: {
      10: "#C1B4B4",
      20: "#83696a",
    },
  },
  fontSize: {
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;

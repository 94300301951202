import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const HeaderUnAuthStyle = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,

  buttonRegister: ({ colors }: Theme) => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 14px;
    margin-left: 12px;
    border: transparent;
    background: ${colors.gradient[6]};
    height: 28px;
    width: 80px;

    span {
      font-family: "SVN-Gilroy";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      color: ${theme.colors.lightText};
    }
  `,
  buttonLogin: ({ colors }: Theme) => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border: 1px solid rgba(149, 149, 157, 0.3);
    border-radius: 14px;
    height: 28px;
    background: ${colors.gradient[1]};

    span {
      font-family: "SVN-Gilroy";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      color: ${theme.colors.white};
    }
  `,
};

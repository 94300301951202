import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const MessageStyle = {
  self: css`
    position: fixed;
    bottom: 22px;
    right: 12.5px;
    z-index: 99999;

    ${media.xl} {
      bottom: 50px;
      right: 10px;
    }
  `,

  btnBackToTop: css`
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    border: none;
    background: ${theme.colors.gradient[1]};
    display: flex;
    align-items: center;
    justify-content: center;
    & .ant-wave {
      display: none;
    }

    svg {
      padding-left: 1px;
      padding-bottom: 1px;
      fill: ${theme.colors.white} !important;
      width: 24px;
      height: 24px;
    }
  `,
  backTitle: css`
    color: ${theme.colors.lightText};
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 6px;
  `,

  group: css`
    display: flex;
    position: relative;
  `,
  iframe: (isOpen: boolean) => css`
    opacity: ${isOpen ? 1 : 0};
    transform: ${isOpen ? "translateY(0)" : "translateY(20px)"};
    visibility: ${isOpen ? "visible" : "hidden"};
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
    display: flex;
    position: absolute;
    bottom: 52px;
    right: 0;
    height: 500px;
  `,
};

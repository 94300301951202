import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { NoInfer } from "react-redux";
import { ReferralResponseType, ReferralStateType } from "types/referral";

import { getReferral } from "./referral.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<ReferralStateType>>
) => {
  builder
    .addCase(getReferral.pending, (state: ReferralStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getReferral.fulfilled,
      (
        state: ReferralStateType,
        action: PayloadAction<ReferralResponseType>
      ) => {
        state.isLoading = false;
        state.dataVipMember.data = { ...action.payload.data };
      }
    );
};

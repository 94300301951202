import { css } from "@emotion/react";

export const reset = css`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
  * {
    font-family: "Inter", sans-serif;
  }
  *:not(.non-reset *) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  a img {
    max-width: 100%;
    height: auto;
  }
  body {
    margin: 0;
  }
`;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJson from "./translation/en.json";
// import msJson from "./translation/ms.json";
import zhTWJson from "./translation/zh-TW.json";

export const resources = {
  en: enJson,
  // ms: msJson,
  zh: zhTWJson,
} as const;

i18n.use(initReactI18next).init({
  fallbackLng: "zh",
  debug: false,
  resources: resources,
});

export { i18n };

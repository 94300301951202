/** @jsxImportSource @emotion/react */

import { MutableRefObject } from "react";
import Slider from "react-slick";

import { ArrowCustomStyle } from "./style.index";

interface Props {
  sliderRefBelow: MutableRefObject<Slider | null | undefined>;
  sliderRefAbove: MutableRefObject<Slider | null | undefined>;
}

export const ArrowNextCustom = (props: Props) => {
  const { sliderRefBelow, sliderRefAbove } = props;

  return (
    <div
      style={{
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
      }}
      css={ArrowCustomStyle.index}
      onClick={() => {
        sliderRefBelow.current?.slickNext();
        sliderRefAbove.current?.slickNext();
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--uil"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M15.54 11.29L9.88 5.64a1 1 0 0 0-1.42 0a1 1 0 0 0 0 1.41l4.95 5L8.46 17a1 1 0 0 0 0 1.41a1 1 0 0 0 .71.3a1 1 0 0 0 .71-.3l5.66-5.65a1 1 0 0 0 0-1.47Z"
        ></path>
      </svg>
    </div>
  );
};

export const ArrowPrevCustom = (props: Props) => {
  const { sliderRefBelow, sliderRefAbove } = props;

  return (
    <div
      style={{
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
      }}
      css={ArrowCustomStyle.index}
      onClick={() => {
        sliderRefBelow.current?.slickPrev();
        sliderRefAbove.current?.slickPrev();
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--uil"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="m8.5 12.8l5.7 5.6c.4.4 1 .4 1.4 0c.4-.4.4-1 0-1.4l-4.9-5l4.9-5c.4-.4.4-1 0-1.4c-.2-.2-.4-.3-.7-.3c-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6c0-.1 0-.1 0 0z"
        ></path>
      </svg>
    </div>
  );
};

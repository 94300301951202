import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const SignupModalStyle = {
  formContainer: () => css`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;
    ${media["2sm"]} {
      margin: 0 16px;
    }
  `,

  buttonSubmit: () => css`
    height: 44px;
    background: ${theme.colors.gray[150]};
    border-radius: 8px;
    border: 0px;
    margin-top: 16px;
    color: ${theme.colors.white};

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    cursor: inherit;
  `,
  buttonActive: () => css`
    background-color: ${theme.colors.darkGreen};
    color: ${theme.colors.text.white};
    cursor: pointer;
  `,
  mrBottom: () => css`
    margin-bottom: 8px;
  `,
  approve: () => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    justify-content: flex-start;
    margin-top: 8px;
    width: 80%;
    margin: auto;

    ${media["2sm"]} {
      width: 100%;
    }

    span:nth-of-type(1) {
      margin-top: -12px;

      ${media["2sm"]} {
        margin-top: 0;
        margin-right: 4px;
      }

      input {
        background-color: ${theme.colors.darkGreen};
        background: ${theme.colors.darkGreen};
      }
    }
    span:nth-of-type(2) {
      text-align: center;
      ${media.md} {
        text-align: left;
        padding-inline-start: 0px;
      }
      a {
        color: ${theme.colors.darkGreen};
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    ${media.sm} {
      width: 100%;
    }
  `,
  login: () => css`
    margin-top: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.gray["300"]};
    margin-bottom: 6px;

    span {
      color: ${theme.colors.darkGreen};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
  optCode: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;

    input {
      width: 100%;
      height: 44px;
      border: 0;
      padding: 0 8px;
      outline: none !important;
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      ::placeholder {
        font-size: 14px;
        color: ${theme.colors.gray[400]};
      }
    }

    button {
      border: 0;
      background-color: ${theme.colors.darkGreen};
      height: 36px;
      padding: 0 8px;
      margin-right: 4px;

      &:disabled {
        background-color: ${theme.colors.gray[200]};
      }

      span {
        line-height: unset !important;
        color: ${theme.colors.white};
      }
    }
  `,
};

import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "api/auth/type";
import { TOKEN, USERNAME } from "contants";
import { ResponseCode } from "contants/response";
import { NoInfer } from "react-redux";
import { AuthStateType } from "types/auth";

import { signInWithCredentials } from "./auth.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<AuthStateType>>
) => {
  builder
    .addCase(signInWithCredentials.pending, (state: AuthStateType) => {
      state.isLoading = true;
    })
    .addCase(
      signInWithCredentials.fulfilled,
      (state: AuthStateType, action: PayloadAction<LoginResponse>) => {
        state.isLoading = false;
        if (action.payload.status !== ResponseCode.SUCCESS) {
          state.messageRes =
            "Incorrect login, username, phone number or password";
          return;
        }
        const { username, token } = action.payload;
        localStorage.setItem(TOKEN, token);
        localStorage.setItem(USERNAME, username);
        state.username = username;
        state.token = token;
        state.messageRes = "";
        state.isAuthenticated = true;
      }
    )
    .addCase(signInWithCredentials.rejected, (state: AuthStateType) => {
      state.isLoading = false;
    });
};

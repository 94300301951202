/** @jsxImportSource @emotion/react */

import ImageNotification from "assets/Desktop/images/homepage/notification-notifi.png";
import Container from "components/Desktop/base/Container";
import { useAppSelector } from "hooks/app-hook";
import { selectAnnouncement } from "store/announcement";

import { MarqueStyle } from "./style.index";

const Marque = () => {
  const dataAnnouncement = useAppSelector(selectAnnouncement);

  return (
    <section css={MarqueStyle.wrapper}>
      <Container>
        <div css={MarqueStyle.item}>
          <img src={ImageNotification} alt="" />
          <div css={MarqueStyle.text}>
            <span>
              {dataAnnouncement.map((item, index) => (
                <div
                  key={index}
                  className="width-text"
                  dangerouslySetInnerHTML={{
                    __html: `${item.title}`,
                  }}
                />
              ))}
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Marque;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { retriveApi } from "api/retrive";

export const getPromotionList = createAsyncThunk(
  "retrive/getPromotionList",
  async (username: string, { rejectWithValue }) => {
    try {
      const result = await retriveApi.getPromotionList(username, { username });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

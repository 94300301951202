import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const UserMenuSidebarStyle = {
  self: ({ colors }: Theme) => css`
    min-width: 280px;
    border-radius: 10px;
    background-color: ${colors.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    height: fit-content;
  `,
  menuBox: css``,
  wallet: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-bottom: 1px solid rgba(175, 175, 181, 0.2);
  `,
  walletUsername: ({ colors }: Theme) => css`
    color: ${colors.bank[1]};
    font-size: 16px;
    font-weight: 700;
  `,

  skeleton: (isLoadingBalance: boolean) => css`
    margin-left: 10px;
    cursor: ${isLoadingBalance ? "default" : "pointer"};
  `,

  flex: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  walletValue: ({ colors }: Theme) => css`
    color: ${colors.black};
    font-size: 20px;
    font-weight: 700;

    .ant-skeleton-title {
      height: 27px !important;
      width: 106.63px !important;
    }
  `,
  menuMd: css`
    flex: 2;
    display: flex;
    flex-direction: row;
    border-left: 1px solid rgba(175, 175, 181, 0.2);
    padding-left: 6px;
    padding-right: 6px;
  `,
  menuItem: (active: boolean) => css`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${active ? theme.colors.gray[500] : "transparent"};
    flex: 1;
    cursor: pointer;
    &:hover {
      background: ${theme.colors.gray[500]};
    }
    svg {
      width: 24px;
      height: 24px;
    }
    .icon-image {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  `,

  menuBoxWrap: css``,
  menuTitle: css`
    font-weight: 750;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.6px;

    padding: 5px 0 5px 10px;
    background-color: #f2f1f1;
  `,

  menuLabel: css`
    color: #1c1c1e;
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
  `,
  buttonContainer: css`
    padding: 0px 16px 8px 16px;
  `,
  btnLogout: ({ colors }: Theme) => css`
    height: 34px !important;
    margin-top: 6px;
    background-color: #f5f5f5;
    box-shadow: none !important;
    color: ${colors.darkGreen};
    font-size: 13px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & .ant-wave {
      color: ${colors.darkGreen};
    }
    &:hover,
    &:active {
      background-color: #f5f5f5 !important;
      color: ${colors.darkGreen} !important;
    }
  `,
  menu2: css`
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  activeMenu: (active: boolean) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 4px 12px 0px 12px;
    background-color: ${active ? theme.colors.gray[500] : "transparent"};
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.gray[500]};
    }

    span {
      margin-top: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: ${theme.colors.gray[300]};
    }
  `,
};

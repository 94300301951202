/** @jsxImportSource @emotion/react */

import Footer from "components/Common/Footer";
import { Modal } from "components/Common/Modal";
import { Header } from "components/Desktop/ui/Header";
import { Outlet } from "react-router-dom";

import { DefaultLayoutStyle } from "./index.style";

export const DefaultLayout = () => {
  return (
    <div css={DefaultLayoutStyle.self}>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />

      <Modal />
    </div>
  );
};

export default DefaultLayout;

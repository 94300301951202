import { NavigateFunction } from "react-router-dom";
import { ThunkActionDispatch } from "redux-thunk";
import { resetAnnouncement } from "store/announcement";
import { resetAuth } from "store/auth";
import { resetBank } from "store/bank";
import { resetBettingRecord } from "store/bettingrecord";
import { resetDeposit } from "store/deposit";
import { resetOrders } from "store/orders";
import { resetUser } from "store/user";
import { resetWithdraw } from "store/withdraw";

export const handleResetData = (
  dispatch: ThunkActionDispatch<any>,
  navigate: NavigateFunction
) => {
  dispatch(resetAuth());
  dispatch(resetUser());
  dispatch(resetOrders());
  dispatch(resetBettingRecord());
  dispatch(resetAnnouncement());
  dispatch(resetBettingRecord());
  dispatch(resetBank());
  dispatch(resetDeposit());
  dispatch(resetWithdraw());
  localStorage.clear();

  navigate("/");
};

import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const DatePickerCustomStyle = {
  self: css`
    & .ant-picker {
      width: 100%;
      height: 44px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
      font-size: 14px;
      background-color: ${theme.colors.white};
      border-radius: 8px;
      color: ${theme.colors.text.black};
      outline: none;
      box-shadow: none;
      font-weight: 500;

      &-disabled {
        opacity: 0.5;
      }

      &-status-error {
        border-color: var(--error-color) !important;
        input,
        input::placeholder {
          color: var(--error-color);
        }
        & .ant-picker-suffix {
          svg {
            color: var(--error-color);
          }
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};

export const antDatePickerOveride = css`
  .date-picker-custom {
    & .ant-picker {
      &-cell-inner {
        width: 35px;
        height: 35px !important;
        line-height: 33px !important;
      }
      &-cell-selected {
        .ant-picker-cell-inner {
          background-color: ${theme.colors.darkGreen} !important;
        }
      }
      &-cell-today {
        .ant-picker-cell-inner:before {
          border: 1px solid ${theme.colors.darkGreen} !important;
        }
      }
      &-header-view {
        button:hover {
          color: ${theme.colors.darkGreen} !important;
        }
      }
      &-footer {
        .ant-picker-today-btn {
          color: ${theme.colors.darkGreen} !important;
        }
      }
    }
  }
`;

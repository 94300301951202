/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Modal } from "antd";
import { ReactComponent as CloseSvg } from "assets/temporary/icons/Close.svg";
import { ReactComponent as NotificationIcon } from "assets/temporary/icons/notification_icon.svg";
import { FORMAT_DATE } from "contants/common";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { theme } from "styles/theme";
import { OpenDetailsType } from "types/announcement";

import { DetailAnnouncementStyle } from "./index.style";
interface Props {
  onClose: () => void;
  details: OpenDetailsType;
}
function DetailAnnouncement({ onClose, details }: Props) {
  const { dataItem, visible } = details;

  const { t } = useTranslation(["modal"]);
  return (
    <Modal
      centered
      open={visible}
      closable={false}
      css={DetailAnnouncementStyle.modalContainer}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      <div css={DetailAnnouncementStyle.container}>
        <div css={DetailAnnouncementStyle.header}>
          <div css={css({ width: 24, height: 24 })} />
          <span>{t("announcementDetail")}</span>
          <Button onClick={onClose} css={DetailAnnouncementStyle.buttonClose}>
            <CloseSvg fill={theme.colors.white} />
          </Button>
        </div>
        {dataItem && (
          <div css={DetailAnnouncementStyle.itemAnnouncement}>
            <div css={DetailAnnouncementStyle.itemIcon}>
              <NotificationIcon />
            </div>
            <div css={DetailAnnouncementStyle.itemContent}>
              <p>{dataItem.title}</p>
              <p>
                {moment(dataItem?.start).format(FORMAT_DATE)} -{" "}
                {moment(dataItem?.end).format(FORMAT_DATE)}
              </p>
              <p>{dataItem?.content}</p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default DetailAnnouncement;

import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const LanguageModalStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    gap: 8px;
  `,
  item: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    border: 1px solid ${theme.colors.darkGreen};
    border-radius: 8px;
    cursor: pointer;
    img {
      width: 1.875rem;
    }
    span {
      flex: 1;
      margin-left: 12px;
    }
  `,
};

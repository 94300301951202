/** @jsxImportSource @emotion/react */

import { listBannerHome } from "contants/banner-list";
import { LegacyRef, MutableRefObject, useRef } from "react";
import Slider from "react-slick";

import { BannerStyle } from "./index.style";
function Banner() {
  const sliderRefAbove: MutableRefObject<Slider | null | undefined> = useRef();
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 250,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
    ref: sliderRefAbove as LegacyRef<Slider> | undefined,
    dots: true,
  };
  return (
    <div css={BannerStyle.index}>
      <Slider {...settings}>
        {listBannerHome.map((item, index) => {
          return (
            <section css={BannerStyle.banner} key={`key-${index}-slider`}>
              <img
                onClick={() => sliderRefAbove.current?.slickNext()}
                src={item}
                alt=""
              />
            </section>
          );
        })}
      </Slider>
    </div>
  );
}

export default Banner;

import { css } from "@emotion/react";

export const GamesSwiperStyle = {
  index: css`
    position: relative;
    width: 100%;
    display: inline-block;
    cursor: pointer;
  `,
  shadow: css`
    width: 17.75rem;
    height: 18.6875rem;
    margin: 0 auto;
    border-radius: 1.5rem;
    box-shadow: 0 4px 12px 0 rgba(107, 123, 168, 0.25);
    display: block;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  `,
  img: css`
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.25s ease-in-out;
    img {
      transform: scale(1);
      width: 100%;
      height: auto;
      display: block;
    }
  `,
};

// import banner1 from "assets/Desktop/images/banner/banner 1.png";
// import banner2 from "assets/Desktop/images/banner/banner 2.png";
// import banner3 from "assets/Desktop/images/banner/banner 3.png";
// import banner4 from "assets/Desktop/images/banner/banner 4.png";
// import banner5 from "assets/Desktop/images/banner/banner 5.png";
// import banner6 from "assets/Desktop/images/banner/banner 6.png";
// import banner7 from "assets/Desktop/images/banner/banner 7.png";
import bannerWelcome from "assets/Desktop/images/banner/welcome-banner.png";

export const listBannerHome = [
  // banner1,
  // banner2,
  // banner3,
  // banner4,
  // banner5,
  // banner6,
  // banner7,
  bannerWelcome,
];

/** @jsxImportSource @emotion/react */
import BottomTabs from "components/Mobile/ui/BottomTabs";
import SideBarLeft from "components/Mobile/ui/SideBarLeft";
import { Outlet } from "react-router-dom";

import { LayoutStyle } from "./index.style";
const DefaultLayout = () => {
  return (
    <div css={LayoutStyle.root}>
      <SideBarLeft />
      <main css={LayoutStyle.main()}>
        <Outlet />
      </main>
      <BottomTabs />
    </div>
  );
};

export default DefaultLayout;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { referralApi } from "api/Referral";

export const getReferral = createAsyncThunk(
  "",
  async (username: string, { rejectWithValue }) => {
    try {
      const response = await referralApi.getReferral(username);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

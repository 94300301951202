import { Helmet } from "react-helmet";

type Props = {
  title: string | undefined;
};

function HelmetCustom(props: Props) {
  const { title = "" } = props;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title + " - Steam"}</title>
      <meta
        name="description"
        content="Steam is a trusted online casino in Malaysia where you can play Slots, Sports Betting, Baccarat, Blackjack, Roulette and more online casino games. Register an account and start playing. Welcome Bonus and Daily Bonus ready to claim"
      />
    </Helmet>
  );
}

export default HelmetCustom;

import { isMobile, sysLangLocal } from "contants/common";
import { sysLang as _sysLang } from "contants/syslang";
import { Device } from "enums/device";
import md5 from "md5";
import { ApiPayload } from "types/api";

const agentId = process.env.REACT_APP_AGENT_ID as string;
const secretKey = process.env.REACT_APP_SECRET_KEY as string;

const hashSignature = (typeName: string | null) =>
  typeName ? md5(agentId + typeName + secretKey) : md5(agentId + secretKey);

export const payloadHelper = (
  methodName: string,
  typeName: string | null,
  data: any
) => {
  const languageLocal = localStorage.getItem(sysLangLocal);

  const payload: ApiPayload<any> = {
    agentId,
    signature: hashSignature(typeName),
    data,
    method: methodName,
    syslang: +(languageLocal || _sysLang["zh-TW"]),
    device: isMobile ? Device.MOBILE : Device.WEB,
  };
  if (!data) {
    delete payload["data"];
  }

  return payload;
};

import { notification } from "antd";
import { useAppSelector } from "hooks/app-hook";
import { selectOpenModal } from "store/modal";

import Bank from "./Bank";
import LanguageModal from "./LanguagePage";
import { RecoveryModal } from "./RecoveryModal";
import { SigninModal } from "./SigninModal";
import { SignupModal } from "./SignupModal";
export const Modal = () => {
  const openModal = useAppSelector(selectOpenModal);

  const [, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      {openModal.includes("signin") && <SigninModal />}
      {openModal.includes("signup") && <SignupModal />}
      {openModal.includes("recoveryPassword") && <RecoveryModal />}
      {openModal.includes("bank") && <Bank />}
      {openModal.includes("language") && <LanguageModal />}
    </>
  );
};

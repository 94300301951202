import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const GaugeItemStyle = {
  index: css``,
  header: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-position: bottom;
    background-repeat: no-repeat;
    span {
      font-size: 0.5rem;
      white-space: nowrap;
      margin-bottom: 0;
    }
    svg {
      position: absolute;
      bottom: 0;

      width: 125px;
      height: 125px;
    }
  `,
  bottom: css`
    margin-top: 19px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.colors.lightText};
  `,
};

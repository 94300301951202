import { useAppDispatch } from "hooks/app-hook";
import HomePage from "pages/Desktop/HomePage";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setOpenModal } from "store/modal";

const ReferralAgent = () => {
  const { code } = useParams();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      navigate(`/?code=${code}`);
      dispatch(setOpenModal("signup"));
    }
  }, []);
  return <HomePage />;
};

export default ReferralAgent;

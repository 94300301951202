/** @jsxImportSource @emotion/react */

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { LoadingStyle } from "./index.style";

export interface LoadingProps {
  height?: number;
  center?: boolean;
  fontSize?: number;
  width?: number | string;
}

const Loading = (props: LoadingProps) => {
  const { fontSize = 40 } = props;
  return (
    <div css={LoadingStyle.self(props)}>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: `${fontSize}px` }} spin />
        }
      />
    </div>
  );
};

export default Loading;

import ServiceImage_3 from "assets/Desktop/images/homepage/service-convenient.png";
import ServiceImage_1 from "assets/Desktop/images/homepage/service-professional.png";
import ServiceImage_2 from "assets/Desktop/images/homepage/service-safety.png";
import ServiceImage_4 from "assets/Desktop/images/homepage/service-speed.png";

import { TypeListItemService } from "./type";

export const LIST_ITEM_SERVICE: TypeListItemService[] = [
  {
    image: ServiceImage_1,
    title: "home:Professional",
    content: "home:contentProfessional",
  },
  {
    image: ServiceImage_2,
    title: "home:Safety",
    content: "home:contentSafety",
  },
  {
    image: ServiceImage_3,
    title: "home:Convenient",
    content: "home:contentConvenient",
  },
  {
    image: ServiceImage_4,
    title: "home:Speed",
    content: "home:contentSpeed",
  },
];

/** @jsxImportSource @emotion/react */

import { CloseOutlined, MessageOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { selectIsOpenChat, setOpenChat } from "store/modal";

import { MessageStyle } from "./index.style";

const Message = () => {
  const dispatch = useAppDispatch();

  const isOpenChat = useAppSelector(selectIsOpenChat);

  const onClick = () => {
    dispatch(setOpenChat(!isOpenChat));
  };

  return (
    <div css={MessageStyle.self}>
      <div css={MessageStyle.group}>
        <iframe
          css={MessageStyle.iframe(isOpenChat)}
          title="My iframe"
          src={"https://tawk.to/chat/66cc176550c10f7a00a0676b/1i66jmppo"}
        />
        <Button
          size="small"
          icon={isOpenChat ? <CloseOutlined /> : <MessageOutlined />}
          css={MessageStyle.btnBackToTop}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Message;

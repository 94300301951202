import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { GameStateType } from "types/game";

import { extraReducers } from "./game.extraReducers";
import { reducers } from "./game.reducers";

export const initialState: GameStateType = {
  isLoading: false,

  dataCategoryDetails: [], // data from api GetGameList
  dataCategoryGameAll: [],
  dataCategoryGameFilter: [], //filter by game_type
  dataSubGame: {
    data: [],
    totalCount: 0,
    totalPages: 0,
  },
  categoryCurrent: "",
  typeGame: "HT",
  provider_code: "",

  nameLoginGame: "",
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers,
  extraReducers,
});

export const selectDataCategoryGameAll = (state: ApplicationRootState) =>
  state.game.dataCategoryGameAll;

export const selectDataCategoryGameFilter = (state: ApplicationRootState) =>
  state.game.dataCategoryGameFilter;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.game.isLoading;

export const selectCategoryCurrent = (state: ApplicationRootState) =>
  state.game.categoryCurrent;

export const selectCategoryDetails = (state: ApplicationRootState) =>
  state.game.dataCategoryDetails;

export const selectDataSubGame = (state: ApplicationRootState) =>
  state.game.dataSubGame;

export const selectTypeGame = (state: ApplicationRootState) =>
  state.game.typeGame;

export const selectProviderCode = (state: ApplicationRootState) =>
  state.game.provider_code;

export const selectNameLoginGame = (state: ApplicationRootState) =>
  state.game.nameLoginGame;

export const {
  resetDataCategoryGameFilter,
  setCategoryCurrent,
  resetDataSubGame,
  updateTypeGameByCategory,
  updateGameProviderCode,
  updateNameLoginGame,
} = gameSlice.actions;

export default gameSlice.reducer;

import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const BackToTopStyle = {
  self: css`
    position: fixed;
    bottom: 72px;
    right: 20.5px;
  `,

  btnBackToTop: css`
    width: 35px !important;
    height: 35px !important;
    border-radius: 50% !important;
    border: none;
    background: ${theme.colors.gradient[1]};
    & .ant-wave {
      display: none;
    }
  `,
  backTitle: css`
    color: ${theme.colors.lightText};
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 6px;
  `,
};

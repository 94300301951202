/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import { ServiceItemStyle } from "./style.index";
import { TypeListItemService } from "./type";

const ServiceItem = (props: TypeListItemService) => {
  const { content, image, title } = props;

  const { t } = useTranslation("home");

  return (
    <div css={ServiceItemStyle.index}>
      <span>
        <img src={image} alt="" />
      </span>
      <div className="message">
        <h4>{t(title)}</h4>
        <p>{t(content)}</p>
      </div>
    </div>
  );
};

export default ServiceItem;

import { PayloadAction } from "@reduxjs/toolkit";
import { GameStateType } from "types/game";

import { initialState } from ".";

export const reducers = {
  resetDataCategoryGameFilter: (state: GameStateType) => {
    state.dataCategoryGameFilter = initialState.dataCategoryGameFilter;
  },
  setCategoryCurrent: (state: GameStateType, action: PayloadAction<string>) => {
    state.categoryCurrent = action.payload;
  },
  resetDataSubGame: (state: GameStateType) => {
    state.dataSubGame = { ...initialState.dataSubGame };
  },
  updateTypeGameByCategory: (
    state: GameStateType,
    action: PayloadAction<string>
  ) => {
    state.typeGame = action.payload;
  },
  updateGameProviderCode: (
    state: GameStateType,
    action: PayloadAction<string>
  ) => {
    state.provider_code = action.payload;
  },

  updateNameLoginGame(
    state: GameStateType,
    { payload }: PayloadAction<string>
  ) {
    state.nameLoginGame = payload;
  },
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { memberApi } from "api/member";
import { MemberPayload } from "types/member";

export const getVipMember = createAsyncThunk(
  "member/getVipMember",
  async (data: MemberPayload, { rejectWithValue }) => {
    try {
      const dataVipMember = await memberApi.getMemberVip(data.username, data);
      return dataVipMember;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

import { css, keyframes } from "@emotion/react";
import notificationBg from "assets/Desktop/images/homepage/notification-background.png";
import { theme } from "styles/theme";

const scrollingText = keyframes`
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const MarqueStyle = {
  wrapper: css`
    min-width: 1200px;
  `,

  item: css`
    background-image: url(${notificationBg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5625rem;
    border-radius: 1.5rem;
    padding: 0.4rem;
    border-radius: 1.5rem;
    box-shadow: 0 4px 12px 0 rgba(107, 123, 168, 0.25);

    overflow: hidden;
  `,
  img: css`
    width: unset;
    height: auto;
  `,
  text: css`
    overflow: hidden;
    width: 100%;
    margin: 0 10px;
    height: 100%;
    display: flex;
    span {
      overflow: hidden;
      white-space: nowrap;
      animation: ${scrollingText} 45s linear infinite;

      display: flex;
      min-width: max-content;
      gap: 200px;
      .width-text {
        min-width: max-content;
      }
      align-items: center;
      width: 100%;
      color: ${theme.colors.text.darkGreen};
    }
  `,
};

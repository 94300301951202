/** @jsxImportSource @emotion/react */

import { UserApi } from "api/user";
import { ReactComponent as IconRefresh } from "assets/Common/icons/arrow-repeat.svg";
import avatar from "assets/temporary/images/avatar_default.png";
import Loading from "components/Common/Loading";
import { PATH } from "contants/path";
import { ResponseCode } from "contants/response";
import { formatNumberWithCommas } from "helpers/formatNumber";
import { useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectUsername } from "store/auth";
import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";

import { HeaderAuthStyle } from "./style.index";

interface Props {
  visibleMenu: string;
  colorText: string;
}

const HeaderAuth = ({ visibleMenu, colorText }: Props) => {
  const { t } = useTranslation(["header", "currency"]);

  const [_isLoading, setIsLoading] = useState(false);

  const profileUser = useAppSelector(selectProfile);
  const balanceUser = useAppSelector(selectBalance);
  const isLoading = useAppSelector(selectBalanceLoading);
  const username = useAppSelector(selectUsername);
  const navigate = useNavigate();

  const getBalance = async () => {
    try {
      setIsLoading(true);
      const res = await UserApi.getMemberBalance(username, {
        username,
      });

      const { status } = res;

      if (status === ResponseCode.SUCCESS) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div css={HeaderAuthStyle.wrapper}>
      <div css={HeaderAuthStyle.infoHeader}>
        <h4 css={HeaderAuthStyle.title(visibleMenu, colorText)}>
          {profileUser && profileUser.username}
        </h4>

        <div css={HeaderAuthStyle.points(visibleMenu, colorText)}>
          {_isLoading || isLoading ? (
            <Loading fontSize={15} />
          ) : (
            <div css={HeaderAuthStyle.currency}>
              <span>
                {t("currency:HKD")}{" "}
                {formatNumberWithCommas(balanceUser?.total || "0.00")}
              </span>
              <IconRefresh onClick={getBalance} />
            </div>
          )}
        </div>
      </div>
      <img
        src={avatar}
        alt=""
        css={HeaderAuthStyle.avatar}
        onClick={() => navigate(PATH.User.TransactionHistory)}
      />
    </div>
  );
};

export default HeaderAuth;

import { configureStore } from "@reduxjs/toolkit";

import announcementReducer from "./announcement";
import authReducer from "./auth";
import bankReducer from "./bank";
import bettingrecordReducer from "./bettingrecord";
import contactReducer from "./contact";
import depositReducer from "./deposit";
import gameReducer from "./game";
import masterReducer from "./master";
import memberReducer from "./member";
import modalReducer from "./modal";
import ordersReducer from "./orders";
import referralReducer from "./referral";
import retriveReducer from "./retrive";
import userReducer from "./user";
import withdrawReducer from "./withdraw";

const rootReducers = {
  auth: authReducer,
  user: userReducer,
  game: gameReducer,
  retrive: retriveReducer,
  bank: bankReducer,
  deposit: depositReducer,
  withdraw: withdrawReducer,
  orders: ordersReducer,
  bettingRecord: bettingrecordReducer,
  announcement: announcementReducer,
  contact: contactReducer,
  modal: modalReducer,
  member: memberReducer,
  master: masterReducer,
  referral: referralReducer,
};

const store = configureStore({
  reducer: rootReducers,
});

export default store;

import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const HeaderAuthStyle = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,

  currency: css`
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      cursor: pointer;
      color: #2f0506;
    }
  `,

  infoHeader: () => css`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-direction: column;

    h4 {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,

  avatar: () => css`
    width: 50px;
    height: 50px;
    cursor: pointer;
  `,

  title: (visibleMenu: string, colorText: string) => css`
    width: 100%;
    text-align: right;
    color: ${visibleMenu ? theme.colors.lightText : colorText};
  `,

  points: (visibleMenu: string, colorText: string) => css`
    display: flex;
    align-items: center;

    span {
      font-size: 13px;
      margin-left: 4px;
      width: 500;
      color: ${visibleMenu ? "#2f0506" : colorText};
    }
  `,
};

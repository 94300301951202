import { css } from "@emotion/react";

export const LayoutStyle = {
  root: css``,
  main: () => css`
    height: 100vh;
    padding-bottom: 5rem !important;
    max-width: 1024px;
    margin: auto;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
};

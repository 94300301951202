import { css, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const InputPasswordStyle = {
  self: ({ colors }: Theme) => css`
    &.ant-input,
    .ant-input {
      font-size: 14px;
      color: #000000;
      font-weight: 500;
    }

    &.ant-input-affix-wrapper,
    .ant-input-affix-wrapper {
      width: 100%;
      height: 44px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;

      background-color: ${theme.colors.white};
      border-radius: 8px;

      outline: none;
      box-shadow: none;

      ${media["2sm"]} {
        display: flex;
        flex-direction: row;
        align-items: center;
        input {
          width: 100%;
          outline: none;
          box-shadow: none;
          height: 34px;
          border: 0px;
        }
      }

      input:-webkit-autofill {
        -webkit-background-clip: text;
      }

      .ant-input-suffix {
        svg {
          color: grey;
        }
      }

      &:focus,
      &:hover {
        border-color: rgba(0, 0, 0, 0.1);
      }
      &-disabled {
        opacity: 0.5;
      }

      &-status-error {
        border-color: var(--error-color) !important;
        input::placeholder {
          color: var(--error-color);
        }
        .ant-input-suffix {
          svg {
            color: var(--error-color);
          }
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};

import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const ServiceItemStyle = {
  index: css`
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 22px;
    background: #f6f2f2;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12) inset;
    padding: 24px 22px 24px 0px;

    span {
      display: flex;
      width: 140px;
      height: 100px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      img {
        width: 88.608px;
        ${media.xxl} {
          width: 68.608px;
        }
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      vertical-align: middle;
      h4 {
        color: ${theme.colors.lightText};
        font-size: 21px;
        font-weight: 600;
        line-height: 25px;
      }
      p {
        color: ${theme.colors.lightText};
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
    }
  `,
};

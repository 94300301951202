import { css } from "@emotion/react";
import { isMobile } from "contants/common";

export const FooterStyle = {
  self: css`
    margin-top: 80px;
    background-color: #414755;
    padding: 24px 0;
    min-width: ${isMobile ? "100%" : "1200px"};
  `,

  footerTop: css`
    display: flex;
    justify-content: center;
    gap: 50px;
    border-bottom: 1px solid #becaec;
    padding-bottom: 24px;
  `,
  box: css`
    text-align: center;
  `,
  boxTitle: css`
    color: #f0f6ff;
    font-size: 14px;
    margin-bottom: 16px;
  `,
  logo: css`
    width: auto;
    height: 35px;
    margin: 0 8px;
  `,

  footerBottom: css`
    /*  */
  `,
  desc: css`
    max-width: 991px;
    text-align: center;
    font-size: 12px;
    color: #a5aabc;
    margin: 24px auto;
    line-height: 1.5;
    font-weight: 400;
  `,
  socials: css`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 24px;
  `,
  socialBtn: css`
    width: 35px !important;
    height: 35px !important;
    transition: all 0.3s ease-in-out;
    border-radius: 50% !important;
    border: 1.5px solid #f0f6ff !important;
    color: #f0f6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #414755;
    svg,
    path {
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      border: 1.5px solid #414755 !important;
      background-color: #f0f6ff;
      svg,
      path {
        fill: #414755;
      }
    }
  `,

  signature: css`
    color: #a5aabc;
    font-size: 12px;
    text-align: center;
  `,
};

import { Global } from "@emotion/react";
import { antDatePickerOveride } from "components/Common/DatePickerCustom/index.style";

import { reset } from "./reset";
import { variablesCss } from "./variables";

const GlobalStyles = () => {
  return (
    <>
      <Global styles={[antDatePickerOveride]} />
      <Global styles={[reset, variablesCss]} />
    </>
  );
};

export default GlobalStyles;

import { TypeGaugeItem } from "./type";

export const LIST_ITEM_GAUGE: TypeGaugeItem[] = [
  {
    score: 60,
    title: "CURRENT SPEED",
    describe: "Sec",
    content: "AVERAGES TIME OF DEPOSIT",
    percent: "M 21.716 78.284 A 40 40 0 0 1 68.16 14.36",
  },
  {
    score: 80,
    title: "TOTAL AMOUNT",
    describe: "Platform",
    content: "PAYMENT PLATFORM PARTNERS",
    percent: "M 21.716 78.284 A 40 40 0 1 1 89.508 43.743",
  },
  {
    score: 90,
    title: "TOTAL AMOUNT",
    describe: "Sec",
    content: "AVERAGES TIME OF WITHDRAW",
    percent: "M 21.716 78.284 A 40 40 0 1 1 88.042 62.361",
  },
  {
    score: 23,
    title: "TOTAL AMOUNT",
    describe: "Platform",
    content: "GAMING PROVIDER PARTNERS",
    percent: "M 21.716 78.284 A 40 40 0 0 1 11.768 38.238",
  },
];

import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const CategorySwiperStyle = {
  index: css`
    width: max-content;
    min-width: 8rem;
    justify-content: center;

    height: auto;
    display: flex;
    align-items: center;

    font-weight: 400;
    line-height: 1.5;

    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    img {
      width: 2rem;
      height: auto;
      margin-right: 0.25rem;
      opacity: 1 !important;

      &:first-of-type {
        display: block;
      }

      &:last-of-type {
        display: none;
      }
    }
    p {
      color: ${theme.colors.lightText};
    }
  `,
};

import { css, Theme } from "@emotion/react";
import { ImageHeadingModal } from "assets/temporary/images";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";
export const BankStyle = {
  modalContainer: () => css`
    .ant-modal-content {
      padding: 0px !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      overflow: hidden;
      background: ${theme.colors.white};
      min-height: 350px;
    }
  `,
  container: () => css``,
  header: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    background-image: url(${ImageHeadingModal});
    backface-visibility: initial;
    height: 75px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: ${theme.colors.white};
    }
  `,
  buttonClose: () => css`
    width: 24px;
    height: 24px;
    border: 0px;
    background-color: transparent;

    img {
      width: 24px;
      height: 24px;
    }
  `,
  content: () => css`
    padding-bottom: 32px;
  `,
  create_box: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 10px;
    height: 84px;
    background: #f5f5f5;
    border-radius: 4px;
    margin: 32px 32px 16px 32px;
    cursor: pointer;
    div:nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: ${theme.colors.darkGreen};
        vertical-align: middle;
      }
    }
  `,
  bankCard: () => css`
    width: 52px;
    height: 52px;

    img {
      width: 100%;
      height: 100%;
    }
  `,
  center: () => css`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: ${theme.colors.text.black};
    }
  `,
  listBank: () => css`
    padding: 0 32px;
  `,
  labelList: () => css`
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: ${theme.colors.text.black};
  `,
  itemBank: () => css`
    position: relative;
    height: 100px;

    ${media.sm} {
      height: 72px;
    }
  `,
  itemBg: () => css`
    width: calc(100%);
    position: absolute;
    top: 0;
    z-index: 1;

    ${media.sm} {
      height: 100%;
    }
  `,
  itemBankInfo: () => css`
    width: calc(100%);
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.white};
    padding: 16px;

    ${media.sm} {
      padding: 8px;
    }

    div:nth-of-type(1) {
      flex: 1;
      display: flex;
      flex-direction: column;

      span:nth-of-type(1) {
        text-transform: uppercase;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      span:nth-of-type(2) {
        text-transform: uppercase;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    div:nth-of-type(2) {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  `,
  // bank mapping
  bank: () => css`
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #636369;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 12px;
  `,
};

export const BankMappingStyle = {
  content: () => css`
    padding: 32px;
  `,
  button: ({ colors }: Theme) => css`
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: ${colors.darkGreen};
    margin-top: 40px;
    color: ${theme.colors.white};
    transition: 0.15s all;

    :hover {
      transform: scale(1.01);
      background-color: ${colors.darkGreen};
      border: ${colors.darkGreen};
      color: ${theme.colors.white} !important;
    }
  `,
};

export const ModalSelectBankStyle = {
  index: () => css`
    width: 480px !important;

    .ant-modal-content {
      padding: 0;
      background-color: #f5f5f5;
      border-radius: 10px;
    }
    button.ant-modal-close {
      color: 95959D !important;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  `,
  selectBank: () => css`
    padding: 12px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    cursor: pointer;
  `,
};

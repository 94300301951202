/** @jsxImportSource @emotion/react */

import partner1 from "assets/Desktop/images/partners/partner-1.png";
import partner2 from "assets/Desktop/images/partners/partner-2.png";
import partner3 from "assets/Desktop/images/partners/partner-3.png";
import partner4 from "assets/Desktop/images/partners/partner-4.png";
import partner5 from "assets/Desktop/images/partners/partner-5.png";
import partner6 from "assets/Desktop/images/partners/partner-6.png";
import partner7 from "assets/Desktop/images/partners/partner-7.png";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import { OurPartnerStyle } from "./index.style";
const listPartner = [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
];
function OurPartners() {
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  const { t } = useTranslation("home");

  return (
    <section css={OurPartnerStyle.index}>
      <div css={OurPartnerStyle.label}>
        <h3>{t("Our Partners")}</h3>
        {/* <span>See all</span> */}
      </div>
      <div css={OurPartnerStyle.boxSwiper}>
        <Slider {...settings}>
          {listPartner.map((item, index) => {
            return (
              <div
                css={OurPartnerStyle.itemPartner}
                key={"item-partner-" + index}
              >
                <img src={item} alt={index.toString()} />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}

export default OurPartners;

/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";

import { CategorySwiperStyle } from "./style.index";
import { TypeCategorySwiper } from "./type";

const CategorySwiper = (props: TypeCategorySwiper) => {
  const { image, imageActive, title } = props;

  const { t } = useTranslation("home");
  return (
    <div css={CategorySwiperStyle.index}>
      <img src={image} alt="" />
      <img src={imageActive} alt="" />
      <p>{t(title)}</p>
    </div>
  );
};

export default CategorySwiper;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ordersApi } from "api/orders";
import { MemberOrderPayload, MemberPromotionPayload } from "api/orders/type";
import { OrderType } from "enums/orders";

export const getMemberDepositOrders = createAsyncThunk(
  "orders/getMemberDepositOrders",
  async (payload: MemberOrderPayload, { rejectWithValue }) => {
    try {
      payload.type = OrderType.DEPOSIT;
      const dataMemberOrder = await ordersApi.memberOrder(
        payload.username,
        payload
      );
      return dataMemberOrder;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMemberWithdrawalOrders = createAsyncThunk(
  "orders/getMemberWithdrawalrders",
  async (payload: MemberOrderPayload, { rejectWithValue }) => {
    try {
      payload.type = OrderType.WITHDRAWAL;
      const dataMemberOrder = await ordersApi.memberOrder(
        payload.username,
        payload
      );
      return dataMemberOrder;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMemberRebateOrders = createAsyncThunk(
  "orders/getMemberRebateOrders",
  async (payload: MemberOrderPayload, { rejectWithValue }) => {
    try {
      payload.type = OrderType.REBATE;
      const dataMemberOrder = await ordersApi.memberOrderRebate(
        payload.username,
        payload
      );
      return dataMemberOrder;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getMemberRewardOrders = createAsyncThunk(
  "orders/getMemberDepositOrders",
  async (payload: MemberOrderPayload, { rejectWithValue }) => {
    try {
      payload.type = OrderType.REWARD;
      const dataMemberOrder = await ordersApi.memberOrderReward(
        payload.username,
        payload
      );
      return dataMemberOrder;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMemberPromotions = createAsyncThunk(
  "orders/getMemberPromotions",
  async (payload: MemberPromotionPayload, { rejectWithValue }) => {
    try {
      const result = await ordersApi.memberPromotion(payload.username, payload);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const BannerStyle = {
  index: css`
    .slick-dots {
      bottom: 25px !important;
    }
  `,
  banner: css`
    display: block;
    width: 100%;
    height: 800px;
    position: relative;
    object-fit: contain;
    overflow: hidden;
    ${media.xxl} {
      height: 500px;
    }
    ${media.xl} {
      height: 400px;
    }
    img {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
      top: 62%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      aspect-ratio: 2.4/1;
      ${media.xsl} {
        top: 70%;
      }
    }
  `,
};

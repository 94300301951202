import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const ModalCustomStyle = {
  modalContainer: () => css`
    ${media["2sm"]} {
      position: fixed;
      width: 100vw !important;
      top: 0;
      left: 0;
      z-index: 1099;
    }
    .ant-modal-content {
      border-radius: 12px !important;
      overflow: hidden;
      background-color: ${theme.colors.white} !important;
      padding: 0px !important;
      ${media["2sm"]} {
        border-radius: 0px !important;
        height: 100vh !important;
        width: 100vw !important;
        overflow-y: auto;
      }
    }
  `,

  header: {
    index: (background: string, height: number) => css`
      padding-top: 26px;
      display: flex;
      background-image: url(${background});
      height: ${height}px;
      background-position: inherit;
      background-repeat: no-repeat;
      background-size: cover;
    `,

    title: () => css`
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      flex-grow: 1;
      color: ${theme.colors.white};
      margin-left: 46px;
    `,
    buttonClose: () => css`
      width: 24px;
      height: 24px;
      border: 0px;
      background-color: transparent;
      display: flex;
      align-items: center;
      margin-right: 22px;
      z-index: 9999;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        z-index: 9999;
      }
    `,
  },
};

/** @jsxImportSource @emotion/react */

import { Button, Modal } from "antd";
import CloseIcon from "assets/temporary/icons/Close.png";

import { ModalCustomStyle } from "./index.style";

interface Props {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  titleModal: string;
  bodyModal: JSX.Element;
  footerModal?: JSX.Element;
  headerBackground?: string;
  heightBackground?: number;
}

export const ModalCustom = (props: Props) => {
  const {
    isOpenModal,
    handleCloseModal,
    titleModal,
    bodyModal,
    footerModal,
    headerBackground = "",
    heightBackground = 0,
  } = props;
  return (
    <Modal
      open={isOpenModal}
      closable={false}
      onCancel={handleCloseModal}
      css={ModalCustomStyle.modalContainer}
      footer={<></>}
      centered
    >
      <div
        css={ModalCustomStyle.header.index(headerBackground, heightBackground)}
      >
        <span css={ModalCustomStyle.header.title}>{titleModal}</span>
        <Button
          onClick={handleCloseModal}
          css={ModalCustomStyle.header.buttonClose}
        >
          <img src={CloseIcon} alt="" />
        </Button>
      </div>

      {bodyModal}

      {footerModal}
    </Modal>
  );
};

interface SysLangType {
  [x: string]: number;
}

export const sysLang: SysLangType = {
  en: 1,
  "zh-CN": 2,
  "zh-TW": 3,
  th: 6,
  vn: 7,
  ms: 10,
};

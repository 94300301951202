import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { AnnouncementType } from "types/announcement";

import { extraReducers } from "./announcement.extraReducers";

const initialState: AnnouncementType = {
  isLoading: false,
  data: [],
  pageSize: 100,
  currentPage: 1,
  totalCount: 0,
  totalPages: 0,
  dataByType: [],
};
const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    resetAnnouncement: () => initialState,
  },
  extraReducers,
});
export const selectIsLoading = (state: ApplicationRootState) =>
  state.announcement.isLoading;
export const selectAnnouncement = (state: ApplicationRootState) =>
  state.announcement.data;
export const selectAnnouncementByType = (state: ApplicationRootState) =>
  state.announcement.dataByType;
export const selectTotalPages = (state: ApplicationRootState) =>
  state.announcement.totalPages;
export const selectCurrentPages = (state: ApplicationRootState) =>
  state.announcement.currentPage;

export const { resetAnnouncement } = announcementSlice.actions;

export default announcementSlice.reducer;

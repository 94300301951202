import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { NoInfer } from "react-redux";
import { MemberVipStateType, VipMemberResponseType } from "types/member";

import { getVipMember } from "./member.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<MemberVipStateType>>
) => {
  builder
    .addCase(getVipMember.pending, (state: MemberVipStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getVipMember.fulfilled,
      (
        state: MemberVipStateType,
        action: PayloadAction<VipMemberResponseType>
      ) => {
        state.isLoading = false;
        state.dataVipMember.data = { ...action.payload.data };
      }
    );
};

import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import {
  MemberOrderRebateResponseType,
  MemberOrderResponseType,
  MemberPromotionResponse,
} from "api/orders/type";
import { NoInfer } from "react-redux";
import { MemberOrderStateType } from "types/orders";

import {
  getMemberDepositOrders,
  getMemberPromotions,
  getMemberRebateOrders,
  getMemberWithdrawalOrders,
} from "./orders.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<MemberOrderStateType>>
) => {
  builder
    .addCase(getMemberDepositOrders.pending, (state: MemberOrderStateType) => {
      state.memberOrders.deposit.isLoading = true;
    })
    .addCase(
      getMemberDepositOrders.fulfilled,
      (
        state: MemberOrderStateType,
        action: PayloadAction<MemberOrderResponseType>
      ) => {
        state.memberOrders.deposit.isLoading = false;
        state.memberOrders.deposit.data = Array.isArray(
          state.memberOrders.deposit.data
        )
          ? [...state.memberOrders.deposit.data, ...action.payload.data]
          : action.payload.data;
        state.memberOrders.deposit.total = action.payload.totalCount;
      }
    );

  builder
    .addCase(
      getMemberWithdrawalOrders.pending,
      (state: MemberOrderStateType) => {
        state.memberOrders.withdrawal.isLoading = true;
      }
    )
    .addCase(
      getMemberWithdrawalOrders.fulfilled,
      (
        state: MemberOrderStateType,
        action: PayloadAction<MemberOrderResponseType>
      ) => {
        state.memberOrders.withdrawal.isLoading = false;
        state.memberOrders.withdrawal.data = Array.isArray(
          state.memberOrders.withdrawal.data
        )
          ? [...state.memberOrders.withdrawal.data, ...action.payload.data]
          : action.payload.data;
        state.memberOrders.withdrawal.total = action.payload.totalCount;
      }
    );

  builder
    .addCase(getMemberRebateOrders.pending, (state: MemberOrderStateType) => {
      state.memberOrders.rebate.isLoading = true;
    })
    .addCase(
      getMemberRebateOrders.fulfilled,
      (
        state: MemberOrderStateType,
        action: PayloadAction<MemberOrderRebateResponseType>
      ) => {
        state.memberOrders.rebate.isLoading = false;
        state.memberOrders.rebate.data = Array.isArray(
          state.memberOrders.rebate.data
        )
          ? [...state.memberOrders.rebate.data, ...action.payload.data]
          : action.payload.data;
        state.memberOrders.rebate.total = action.payload.totalCount;
      }
    );

  builder
    .addCase(getMemberPromotions.pending, (state: MemberOrderStateType) => {
      state.memberPromotions.isLoading = true;
    })
    .addCase(
      getMemberPromotions.fulfilled,
      (
        state: MemberOrderStateType,
        action: PayloadAction<MemberPromotionResponse>
      ) => {
        state.memberPromotions.isLoading = false;
        state.memberPromotions.data = action.payload.data;
        state.memberPromotions.total = action.payload.totalCount;
      }
    );
};

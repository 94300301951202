import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ReferralResponseType } from "types/referral";

export const referralApi = {
  async getReferral(username: string) {
    const response = await axiosRequest.post<ReferralResponseType>(
      "/",
      payloadHelper("Referral", username, { username })
    );
    return response.data;
  },
};

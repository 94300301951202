import { css, Theme } from "@emotion/react";

export const LogoAuthStyle = {
  logoApp: ({ colors }: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 12px;
    position: relative;

    img {
      width: 84px;
    }

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: ${colors.gray["300"]};
      margin-top: 8px;
    }
  `,
};

import { useCallback, useEffect, useState } from "react";

export const useCountDownTime = (
  timeCountDown: number,
  isTriggerTimer: boolean
) => {
  const [time, setTime] = useState<number>(timeCountDown);

  useEffect(() => {
    if (!isTriggerTimer) return;

    const timer = setInterval(() => {
      if (time > 0) {
        setTime((prev) => prev - 1);
        return;
      }

      clearInterval(timer);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time, isTriggerTimer]);

  const countDown = useCallback(() => {
    if (!isTriggerTimer) return;

    const minutes = Math.floor(time / 60);
    const second = time % 60;

    return `0${minutes}:${second < 10 ? 0 : ""}${second}`;
  }, [time]);

  return [countDown(), setTime, time];
};

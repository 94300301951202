import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { GetPromotion, GetPromotionListResponse } from "./type";

export const retriveApi = {
  async getPromotionList(username: string, payload: GetPromotion) {
    const { data } = await axiosRequest.post<GetPromotionListResponse>(
      "/",
      payloadHelper("PromotionList", username, payload)
    );
    return data;
  },
};

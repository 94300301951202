import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { WithdrawStateType } from "types/withdraw";

import { extraReducers } from "./withdraw.extraReducers";

export const initialState: WithdrawStateType = {
  isLoading: false,
  dataWithdrawal: {
    player_bank: [],
    currencies: [],
    setting: {
      min_withdrawal: 0,
      max_withdrawal: 0,
      daily_withdrawal_limit: 0,
      daily_withdrawal_times: 0,
    },
    turnover: {
      target: 0,
      current: 0,
    },
  },
};

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {
    resetWithdraw: () => initialState,
  },
  extraReducers,
});

export const selectDataWithdraw = (state: ApplicationRootState) =>
  state.withdraw.dataWithdrawal;

export const selectIsLoadingWithdraw = (state: ApplicationRootState) =>
  state.withdraw.isLoading;

export const { resetWithdraw } = withdrawSlice.actions;

export default withdrawSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
export type ModalType =
  | "signin"
  | "signup"
  | "recovery"
  | "withdraw"
  | "deposit"
  | "bank"
  | "recoveryPassword"
  | "language"
  | "";

export interface ModalStateType {
  openModal: ModalType;
  openSidebar: boolean;
  isOpenChat: boolean;
}
export const initialState: ModalStateType = {
  openModal: "",
  openSidebar: false,
  isOpenChat: false,
};
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setOpenModal: (state: ModalStateType, action: PayloadAction<ModalType>) => {
      state.openModal = action.payload;
    },
    setOpenSidebar: (state: ModalStateType, action: PayloadAction<boolean>) => {
      state.openSidebar = action.payload;
    },
    setOpenChat: (state: ModalStateType, action: PayloadAction<boolean>) => {
      state.isOpenChat = action.payload;
    },
  },
});
export const selectOpenModal = (state: ApplicationRootState) =>
  state.modal.openModal;
export const selectOpenSidebar = (state: ApplicationRootState) =>
  state.modal.openSidebar;
export const selectIsOpenChat = (state: ApplicationRootState) =>
  state.modal.isOpenChat;
export const { setOpenModal, setOpenSidebar, setOpenChat } = modalSlice.actions;
export default modalSlice.reducer;
